@import "css/units.scss";
@import "css/colors.scss";
@import "css/z-index.scss";
@import "css/theme.scss";

.cardContainerOverlay {
    position: fixed;
    pointer-events: none;
    z-index: $z-index-card;
}

.cardContainer {
    position:absolute;
    pointer-events: auto;
    z-index: $z-index-card;
    margin: 0.5rem 2rem;
    min-width: 468px;
}

.leftCard, .rightCard {
    height: 90%;
    position: absolute;
    top: 5%;
    background: $ui-white;
    border: 1px solid $ui-tertiary;
    width: .75rem;
    z-index: 10;
    opacity: 0.9;
    overflow: hidden;
}

.leftCard {
    left: -.75rem;
    border-right: 0;
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
}

.rightCard {
    right: -.75rem;
    border-left: 0;
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

.leftCard::after, .rightCard::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 2.5rem;
    width: 100%;
    @include theme{
      background-color: getVar("primary_color");
    }
}

.leftButton, .rightButton {
    position: absolute;
    top: 50%;
    margin-top: -15px;
    z-index: 20;
    user-select: none;
    cursor: pointer;

    height: 44px;
    width: 44px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.25s ease;

    @include theme{
        background-color: getVar("primary_color");
        box-shadow: 0 0 0 4px getVar("primary_box_shadow_color");
    }
}

.leftButton:hover, .rightButton:hover {
    transform: scale(1.125);
    @include theme{
        box-shadow: 0 0 0 6px getVar("primary_box_shadow_color");
    }
}

.leftButton img, .rightButton img{
    width: 1.75rem;
}

.leftButton {
    left: -27px;
}

.rightButton {
    right: -27px;
}

.card {
    border: 1px solid $ui-tertiary;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    cursor: move;
    z-index: 20;
    overflow: hidden;
    box-shadow: 0px 5px 25px 5px $ui-black-transparent;
    align-items: center;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.headerButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-size: 0.625rem;
    font-weight: bold;
    @include theme{
        background-color: getVar("primary_color");
        border-bottom:1px solid getVar("primary_border_color");
    }
}

.headerButtonsHidden {
    border-bottom: 0px;
}

.headerButtonsRight {
    display: flex;
    flex-direction: row;
}

.headerButtons img {
    margin-bottom: 2px;
}

.shrinkExpandButton {
    cursor: pointer;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.75rem;
}

.shrinkExpandButton:hover, .allButton:hover {
    background-color: $ui-black-transparent;
}

.removeButton, .allButton {
    cursor: pointer;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.75rem;
}

.removeButton:hover, .allButton:hover {
    background-color: $ui-black-transparent;
}

.stepTitle {
    font-size: 0.9rem;
    margin: 0.9rem;
    text-align: center;
    font-weight: bold;
    color: $text-primary;
}

.stepBody {
    width: 100%;
    background: $ui-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;

    /* Min height prevents layout changing when images change */
    min-height: 256px;
}

.stepVideo {
}

.stepImage {
    max-width: 450px;
    max-height: 200px;
    object-fit: contain;
    background: #F9F9F9;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    overflow: hidden;
    margin: 0 0.5rem 0.5rem;
}

.decks {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 1rem 0.5rem;
}

.deck {
    display: flex;
    flex-direction: column;
    margin: 0 8px 8px;
    cursor: pointer;
    border: 1px solid $ui-black-transparent;
    border-radius: 0.25rem;
    overflow: hidden;
}

.deckImage {
    width: 200px;
    height: 100px;
    object-fit: cover;
}

.deckName {
    color: $motion-primary;
    font-weight: bold;
    font-size: 0.85rem;
    margin: .625rem 0px;
    text-align: center;
    font-weight: bold;
    text-align: center;
    max-width: 200px;
}

.helpIcon {
    height: 1.25rem;
}

.closeIcon {
    height: 1.25rem;
    margin: .125rem 0; /* To offset the .25rem difference in icon size */
}

[dir="ltr"] .helpIcon {
    margin-right: 0.25rem;
}

[dir="rtl"] .helpIcon {
    margin-left: 0.25rem;
}

[dir="ltr"] .closeIcon {
    margin-left: 0.25rem;
}

[dir="rtl"] .closeIcon {
    margin-right: 0.25rem;
}

.seeAll {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
}

.seeAllButton {
    cursor: pointer;
    padding: 0.5rem 1rem;
    background-color: $motion-primary;
    color: white;
    font-weight: bold;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    color: $ui-white;
    font-size: .75rem;
    font-weight: bold;
    line-height: 1rem;
    text-align: center;
}

[dir="ltr"] .seeAllButton img {
    margin-left: 0.5rem;
}

[dir="rtl"] .seeAllButton img {
    margin-right: 0.5rem;
}

.stepsList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.activeStepPip, .inactiveStepPip {
    width: 0.5rem;
    height: 0.5rem;
    margin: 0 0.25rem;
    border-radius: 100%;
    background: $ui-white-transparent;
}

.activeStepPip {
    background: $ui-white;
    box-shadow: 0px 0px 0px 2px $ui-black-transparent;
}

.hidden {
    display: none;
}
