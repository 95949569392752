@import "css/theme";

.container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  vertical-align: middle;
  position: relative;
  height: 100%;
  line-height: 100%;
  cursor: pointer;
  outline: none;
  margin-right: 24px;
}

.settingIcon {
  height: 20px;
}

.contentBox {
  padding: 30px 0 30px 30px;
  height: 400px;
  width: 400px;
}

.box {
  padding: 30px 0 30px 30px;
}

.contact {
  color: #7A7A7A;
  font-size: 18px;
  line-height: 18px;
  text-align: left;

  p {
    display: flex;
    margin: 14px 14px 14px 4px;

    a {
      text-decoration: none;
      color: #7A7A7A;
      font-size: 18px;
      cursor: pointer;

      &:hover {
        @include theme {
          color: getVar("primary_color");
        }
      }
    }

    span:first-child {
      min-width: 150px;
    }
  }
}

.qrcode {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  text-align: center;
  font-size: 12px;
  color: #aaa;
  width: 98px;
  margin: 60px 0 0 0;
}

.logo {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  >div {
    width: 135px;
    margin-right: 35px;
    margin-top: 14px;
  }

  img {
    max-width: 100%;
    max-height: 135px;
  }
}

.languageBox {
  padding: 30px 0 30px 30px;
  height: 400px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .attachment {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 5px;
    z-index: 98;
    position: relative;

    &>span {
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
      font-size: 16px;
    }

    &>p {
      display: inline-block;
      height: 20px;
      vertical-align: middle;
      position: relative;
      cursor: pointer;
      flex-grow: 1;

      @include theme {
        background-color: getVar("primary_border_hover_color");
      }
    }

    &>p>input {
      width: 155px;
      opacity: 0;
    }

    .filename {
      margin: 0;
      position: absolute;
      z-index: 2;
      font-size: 12px;
      width: 100%;
      height: 100%;
      color: #666;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &::before {
      content: "";
      width: 17px;
      height: 20px;
      background: url('../../../asset/image/attachment.png') no-repeat;
      background-size: 100%;
    }

    .inputCsv {
      position: absolute;
      width: 100%;
      height: 20px;
      border: 1px solid;
      z-index: 99;
      opacity: 0;
    }


  }

  .helpUs {
    cursor: pointer;
    opacity: 1;
    font-size: 16px;
    letter-spacing: 0px;
    margin-bottom: 5px;
    text-decoration: none;

    @include theme {
      color: getVar('primary_hover_color')
    }
  }
}

.themeItem {
  width: 120px;
}

.localeItem {
  min-width: 180px;
}

.settingWrapper {
  border-radius: 10px 10px 5px 5px;
}

.header {
  height: 46px;
  min-height: 46px;
  display: flex;
  align-items: center;
  border-radius: 10px 10px 0px 0px;

  @include theme {
    background-color: getVar("primary_color");
  }

  .icon {
    margin-left: 27px;
    display: flex;
    align-items: center;
  }

  .title {
    font-size: 18px;
    font-weight: 700;
    margin-left: 15px;

    @include theme {
      color: getVar("invert_text_color")
    }
  }
}
