
@import "css/colors.scss";
@import "css/units.scss";

.playButton {
    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;  /* Mask the icon animation */
    width: 2.5rem;
    height: 2.5rem;
    background-color: $sound-primary;
    color: $ui-white;
    border-radius: 50%;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    user-select: none;
    cursor: pointer;
    transition: all 0.15s ease-out;
}

.playButton {
    position: absolute;
    top: .5rem;
    z-index: auto;
}

.playButton:focus {
    outline: none;
}

.playIcon {
  width: 50%;
}

[dir="ltr"] .playButton {
    right: .5rem;
}

[dir="rtl"] .playButton {
    left: .5rem;
}
