//导入theme.scss
@import "css/theme.scss";
@import "css/colors.scss";
@import "css/units.scss";

.container {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: bold;

  @include theme {
    color: getVar("invert_text_color");
    background-color: getVar('primary_color');
  }

  .left {
    position: relative;
    height: 100%;
    display: flex;
    // gap: 12px;
    align-items: center;

    .title {
      margin: 0 .25rem;
      padding: 0 0.75rem;

      img {
        width: 98px;
        height: $menu-bar-height;
        transition: all 0.2s;

        &:hover {
          scale: 1.1;
        }
      }
    }
  }

  .right {
    position: relative;
    height: 100%;
    color: #fff;
    // font-size: 26px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: end;
  }

}


.menuItem {
  position: relative;
  display: flex;
  height: 100%;
  margin: 0 .25rem;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;

  &:hover {
    background-color: $ui-black-transparent;
  }

}

.dropdownContent {
  position: relative;
  display: flex;
  background-color: transparent;
  line-height: 1.5;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  @include theme {
    color: (getVar("invert_text_color"));
  }

  span {
    margin: 0 0.25rem 0 0.25rem;
  }

}

.divider {
  margin: 0 .5rem;
  height: 34px;
}
