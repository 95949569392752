/* make sure to keep these in sync with other constants,
e.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */

$space: 0.5rem;

$sprites-per-row: 5;

$menu-bar-height: 3rem;
$language-selector-width: 3rem;
$sprite-info-height: 7rem;
$stage-menu-height: 2.75rem;

$library-header-height: 3.125rem;
$library-filter-bar-height: 2.5rem;

$stage-standard-border-width: 0.0625rem;
$stage-full-screen-border-width: 0.1875rem;
$stage-full-screen-stage-padding: 0.1875rem;

$form-radius: calc(#{$space} / 2);

/* layout contants from `layout-constants.js` */
$full-size: 1095px;
$full-size-paint: 1249px;

$menu-bar-standard-font-size: 0.75rem;
$menu-bar-large-font-size: 0.875rem;
$menu-bar-button-size: 2rem;

$menu-bar-item-max-width: 12rem;
