
/* custom dialog css */
.custom-dialog-cover{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 99999999;
}
.custom-dialog{
    position: absolute;
    top: 130px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 350px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 10px 20px;
    padding-right: 5px;
    z-index: 999999;
    box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.1);
    opacity: 1;
}
.child-custom-dialog{
    position: absolute;
    top: 130px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 350px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 10px 20px;
    z-index: 999999;
    box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.1);
    opacity: 1;
}
.title-wrapper{
    width: 100%;
}
.title-wrapper-first-span{
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #D0011A;
    vertical-align: middle;
}
.prompt .title-wrapper span:first-child{
    vertical-align: middle;
    margin-left: 5px;
    font-size: 20px;
    font-weight: bold;
    color: #D0011A;
    width: auto;
    background: none;
}
.title-wrapper-last-span{
    vertical-align: middle;
    margin-left: 5px;
    font-size: 18px;
    font-weight: bold;
    color: #D0011A;
}

.content-wrapper{
    color: #585757;
    font-size: 15px;
    line-height: 15px;
    user-select: text;
    display: flex;
    flex-direction: column;
}
.have-btns-content{
    margin-top: 20px;
    line-height: 20px;
}
.content-detail{
    padding: 8px 0 0 15px;
    max-height: 260px;
    overflow-y: auto;
    padding-right: 12px;
    user-select: text;
}
.update-little-version{
    font-size: 15px;  
}
#update-content-detail::-webkit-scrollbar {
    width: 8px;
    height: 0;
}
#update-content-detail::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: #cecdce;
}
#update-content-detail::-webkit-scrollbar-track{
    width: 0;
    background: #eee;
    border-radius: 10px;
}
.spaceline{
    height: 20px;
}
.content-detail span{
    display: block;
}
.btns-wrapper{
    text-align: right;
    margin-top: 25px;
    margin-bottom: 10px;
    padding-right: 10px;
}
.dialog-btns{
    padding: 6px 20px;
    font-size: 15px;
    background: #fff;
    border: 1px solid #E7E7E7;
    border-radius: 15px;
    outline: none;
    margin-right: 20px;
    transition: all 0.3s;
    cursor: pointer;
    max-width: 160px;
    max-height: 34px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color:rgba(0, 0, 0, 0.65)
}
.dialog-btns.after-update-dialog{
    color: #aaa;
    border-radius: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 10px;
}
.dialog-btns.btn-small{
    padding: 6px 10px;
}
.dialog-btns.btn-small.btn-enterMpy{
    max-width: 180px;
    overflow: none;
    text-overflow: none;
    white-space: none;
}
.dialog-btns.btn-small.online-install{
    color: #D0011A;
    border-color: #D0011A;
}
.now-update-dialog{
    background: #D0011A;
    color: #fff;
    border-radius: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.dialog-btns:last-child{
    margin-right: 0;
}
.dialog-btns:hover{
    color: #D0011A;
}
.dialog-btns.after-update-dialog:hover{
    color: #aaa;
}
.now-update-dialog:hover{
    background: #C9384A;
    color: #fff;
}
.closeBtn{
    position: absolute;
    top: 5px;
    right:5px;
    width: 18px;
    height: 18px;
    background-color: #E4E4E4;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;
    line-height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.closeBtn:hover{
    transform: scale(1.15);
}
.closeBtn img{
    width:80%;
    height: 80%;
}
