 @import "css/theme.scss";
 @import "css/z-index.scss";

 .container {
   position: absolute;
   left: 0;
   width: 130px;
   height: 40px;
   bottom: 0px;
   z-index: $z-index-extension-button;
   cursor: pointer;
   user-select: none;

   .btnContainer {
     width: 100%;
     height: 100%;

     @include theme {
       background-color: getVar("primary_color");
     }

     button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        outline: none;
        width: 100%;
        height: 100%;
        cursor: pointer;
        font-size: 11.2px;
        column-gap: 12px;

        @include theme {
          color: getVar('invert_text_color');
        }
     }

   }
 }
