@import "css/theme.scss";

.container {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;

  &.disabled {
    cursor: not-allowed;
  }

  input {
    display: none;

    &:checked+span {
      @include theme {
        background-color: getVar("primary_color");
        color: getVar("white_text_color");
      }

      &::before {
        @include theme {
          box-shadow: inset 0 0 0 0.4375em darken(getVar("primary_color"), 20%);
        }
      }
    }
  }

  span {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em;
    transition: 0.25s ease;

    @include theme {
      color: getVar("text_secondary_color")
    }

    &::before {
      display: flex;
      flex-shrink: 0;
      content: "";
      background-color: #fff;
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
      margin-right: 0.375em;
      transition: 0.25s ease;
      box-shadow: inset 0 0 0 0.125em #00005c;

      @include theme {
        box-shadow: inset 0 0 0 0.125em getVar("primary_color");
      }
    }

    &:hover {
      @include theme {
        background-color: lighten(getVar("primary_color"), 30%);
      }
    }
  }

}

.radioGroup {
  display: flex;
  flex-wrap: wrap;
  &.vertical {
    flex-direction: column;
  }

  &.disabled {
    cursor: not-allowed;
  }
}
