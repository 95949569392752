@import "css/colors.scss";
@import "css/units.scss";

.modalContent {
    width: 700px;
    margin: 50px auto; 
    color:#575e75
}

.body {
    background: $ui-white;
    padding: 1.5rem 2.25rem;
}

.body input[type="checkbox"],
.body input[type="radio"] {
    margin: 3px;
}

/* Blocks workspace for custom procedure declaration editor */
.workspace {
    min-height: 200px;
    position: relative;
}

.workspace :global(.injectionDiv){
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.workspace :global(.blocklySvg) {
    background-color: $ui-primary;
}

/* Row of "card" buttons for modifying custom procedures */
.optionsRow {
    display: flex;
    justify-content: space-between;
}

.optionCard {
    background: white;
    border: 2px solid $ui-black-transparent;
    border-radius: $space;
    padding: calc(#{$space * 2});
    text-align: center;
    flex-grow: 1;
    cursor: pointer;
    transition: all 0.2s;
    flex-basis: 100px;
}

.optionCard:hover {
    border: 2px solid $motion-primary;
    box-shadow: 0px 0px 0px 4px $motion-transparent;
}

.optionCard + .optionCard {
    margin-left: 1rem;
}

.optionIcon {
    max-height: 48px;
    margin-bottom: 0.5rem;
}

.optionTitle {
    font-weight: bold;
}

/* "Run without screen refresh" row */
.checkboxRow {
    margin-top: 1rem;
}

/* Confirmation buttons at the bottom of the modal */
.buttonRow {
    margin-top: 1rem;
    font-weight: bolder;
    text-align: right;
}

.buttonRow button {
    border: 1px solid $ui-black-transparent;
    border-radius: 0.25rem;
    padding: 0.75rem 1rem;
    background: white;
    font-weight: bold;
    font-size: 0.85rem;
    color:#575e75
}

.buttonRow button.okButton {
    background: $motion-primary;
    border: $motion-primary;
    color: white;
}

[dir="ltr"] .buttonRow button + button {
    margin-left: 0.5rem;
}

[dir="rtl"] .buttonow button + button {
    margin-right: 0.5rem;
}
