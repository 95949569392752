@import "css/colors.scss";
@import "css/units.scss";
@import "css/theme";

.libraryItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-basis: 160px;
  position: relative;
  height: 160px;
  max-width: 160px;
  margin: $space;
  padding: 1rem 1rem 0 1rem;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: $text-primary;
  background: white;
  border-width: 2px;
  border-style: solid;
  border-color: $ui-black-transparent;
  border-radius: $space;
  text-align: center;
  cursor: pointer;
}

.libraryItemExtension {
  align-self: stretch;
}

.libraryItem:hover {
  border-width: 2px;

  @include theme {
    border-color: getVar("primary_color");
  }
}

.hidden {
  display: none;
}

.disabled {
  opacity: .5;
  cursor: auto;
}

.disabled:hover {
  border-color: $ui-black-transparent;
}

.libraryItemImageContainerWrapper {
  height: 100px;
  width: 100%;
  position: relative;
}

.libraryItemImageContainer {
  position: absolute;
  height: 100px;
  width: 100%;
}

.libraryItemInsetImageContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -2.5rem;
  align-self: flex-start;
  background-color: $pen-primary;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 0.5rem;
  border: 0.25rem solid $ui-white;
}

[dir="ltr"] .libraryItemInsetImageContainer {
  margin-left: 1rem;
}

[dir="rtl"] .libraryItemInsetImageContainer {
  margin-right: 1rem;
}

.libraryItemImage {
  max-width: 100%;
  max-height: 100%;
}

.libraryItemInsetImage {
  width: 2.5rem;
}

.libraryItemName {
  width: 80%;
  margin: 0.25rem 0;
  text-align: center;

  /*
      For truncating overflowing text gracefully
      Min-width is for a bug: https://css-tricks.com/flexbox-truncated-text
  */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
}

.featuredItem {
  flex-basis: 220px;
  max-width: 220px;
  height: 251px;
  overflow: hidden;
  padding: 0;
}

.featuredImageContainer {
  position: relative;
  border-bottom: 1px solid #E9EEF2;
  height: 136px;
}

.featuredImage {
  width: 100%;
  height: 100%;
}

.featuredText {
  font-weight: bold;
  padding: 10px;
  /* height: 140px; */
  width: 300px;
}

[dir="ltr"] .featuredExtensionText {
  text-align: left;
  padding-left: 1.25rem;
}

[dir="rtl"] .featuredExtensionText {
  text-align: right;
  padding-right: 1.25rem;
}

.featuredDescription {
  display: block;
  font-weight: normal;
  line-height: 1.375rem;
  padding-top: .3125rem;
  padding-bottom: .25rem;
}

.featuredExtensionMetadata {
  width: 100%;
  padding: 0 1.25rem 1rem 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 12px;
  color: $text-primary;
  opacity: 0.75;
}

[dir="ltr"] .featuredExtensionMetadata {
  text-align: left;
}

[dir="rtl"] .featuredExtensionMetadata {
  text-align: right;
}

.featuredExtensionRequirement {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.featuredExtensionCollaboration {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.featuredExtensionMetadataDetail {
  height: 100%;
  padding-top: 0.4rem;
  font-weight: bold;
}

.featuredExtensionMetadataDetail img {
  margin-right: 0.25rem;
}

.comingSoonText {
  position: absolute;
  background-color: $data-primary;
  border-radius: 1rem;
  box-shadow: 0 0 .5rem hsla(0, 0%, 0%, .25);
  padding: .5rem 1rem;
  font-size: .875rem;
  font-weight: bold;
  color: $ui-white;
}

[dir="ltr"] .comingSoonText {
  transform: translate(calc(2 * #{$space}), calc(2 * #{$space}));
}

[dir="rtl"] .comingSoonText {
  transform: translate(calc(-2 * #{$space}), calc(2 * #{$space}));
}
