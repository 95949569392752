@import "css/colors.scss";
@import "css/units.scss";
@import "css/theme.scss";

.tagButton {
  padding: .625rem 1rem;
  background: $motion-primary;
  border-radius: 1.375rem;
  height: $library-filter-bar-height;

  @include theme {
    color: getVar("invert_text_color");
    background: getVar("primary_color");
  }
}

.tagButtonIcon {
  max-width: 1rem;
  max-height: 1rem;
}

.active {
  @include theme {
    background: $data-primary;
  }
}
