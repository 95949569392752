@import "css/theme.scss";
@import "css/colors.scss";
$extension-card-width: 218px; // 212px

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.extensionLibraries {
  position: absolute;
  z-index: 100000;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #f9f9f9;

  .divider {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #e8e8e8;
  }

  .header {
    width: 100%;
    height: 3rem;
    min-height: 50px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 13px;
    color: white;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 900;

    @include theme {
      background-color: getVar('primary_color');
    }

    .search {
      width: $extension-card-width;
      height: 29px;
      display: flex;
      border-radius: 6px;

      @include theme {
        background-color: lighten(getVar("primary_color"), 10%);
      }

      .searchIconContainer {
        width: 39px;
        display: flex;
        justify-content: center;
        align-items: center;

        .searchIcon {
          path {
            @include theme {
              fill: getVar("invert_text_color");
            }
          }

        }
      }

      .searchInputContainer {
        flex-grow: 1;

        input::placeholder {
          @include theme {
            color: getVar("invert_text_color");
            font-size: 14px;
            font-weight: 500;
          }
        }

        .searchInput {
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
          background: none;
          font-size: 14px;
          font-weight: 400;
          padding: 0 7px;
          box-sizing: border-box;
          border-radius: 0 6px 6px 0;
          transition: all 0.2s ease-in-out;

          @include theme {
            background-color: lighten(getVar("primary_color"), 10%);
            color: getVar("primary_text_active_color");
          }

          &:focus {

            @include theme {
              background-color: lighten(getVar("primary_color"), 20%);
            }
          }
        }
      }
    }

    .tabs {
      position: relative;
      display: flex;
      text-align: center;
      height: 100%;

      .tab {
        height: 100%;
        width: 90px;
        line-height: 40px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        &:hover {
          background-color: $ui-black-transparent;
        }
      }

      .tabSelected {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 90px;
        line-height: 40px;
        cursor: pointer;
        box-sizing: border-box;
        background-color: $ui-black-transparent;
        position: relative;

        &::after {
          content: '';
          width: 100%;
          height: 4px;
          position: absolute;
          bottom: 0px;

          @include theme {
            background-color: getVar("primary_active_color");
            // background-color: #A26A00;
          }

        }
      }

    }

    .closeButton {
      cursor: pointer;
      height: 16px;
      display: flex;
      width: $extension-card-width;
      align-items: center;
      gap: 10px;


      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .selectedTags {
    display: inline-flex;
    align-items: center;
    padding: 6px 11px 7px 17px;
    background: #F1F1F1;
    border-radius: 8px;
    height: 40px;
    box-sizing: border-box;

    .selectedTagLeft {
      min-width: 90px;
      display: flex;
      column-gap: 14px;

      .selectedTag {
        height: 30px;
      }
    }

    .selectedTagRight {
      user-select: none;
      cursor: pointer;
      padding-left: 90px;
      font-size: 12px;
      display: flex;
      align-items: center;
      column-gap: 6px;

      @include theme {
        color: #C4C3C3;
      }
    }
  }

  .searchBar {
    width: 100%;
    display: flex;
    padding: 12px 0;
    min-width: 1024px;
    box-sizing: border-box;
    padding-right: 13px;
    // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    height: 60px;
    align-items: center;

    @include theme {
      // box-shadow: 7px -12px 20px 0px getVar("primary_box_shadow_color");
    }

    .searchButton {
      flex-basis: 124px;
      display: flex;
      justify-content: center;
      flex-shrink: 0;
      position: relative;

      &.selected {
        button {
          @include theme {
            color: getVar("primary_color");

            &::before {
              content: '^';
              position: absolute;
              right: -12px;
              top: 11px;
              transform: rotate(0deg);
            }
          }
        }
      }

      button {
        border: none;
        background-color: transparent;
        position: relative;
        cursor: pointer;
        text-decoration: underline;
        height: 28px;

        @include theme {
          color: getVar("text_secondary_color");
        }

        &:hover {
          @include theme {
            color: getVar("primary_color");
          }
        }

        &::before {
          content: '^';
          position: absolute;
          right: -12px;
          transform: rotate(180deg);
        }


      }
    }

    .searchContent {
      flex-grow: 1;
      display: flex;
      align-items: center;
      position: relative;
      gap: 13px;

      &::before {
        content: '';
        width: 1px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: #D8D8D8;
      }

      .searchBarLeft {
        display: flex;
        align-items: center;
        position: relative;
        flex-grow: 1;
        box-sizing: border-box;
        flex-wrap: wrap;
        margin-left: 20px;

        .categoryFilter {
          @include flexCenter();
          flex-shrink: 0;
          gap: 13px;
          box-sizing: border-box;
          padding-right: 40px;


          .filterContent {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 12px;
            height: 36px;
          }
        }

        .statusFilter {
          flex-shrink: 0;
        }
      }

      .searchBarRight {
        box-sizing: border-box;
        @include flexCenter();
        gap: 6px;
        font-size: 14px;
        cursor: pointer;
        flex-basis: 270px;
        flex-shrink: 0;
        display: flex;
        justify-content: flex-end;

        @include theme {
          color: getVar("text_secondary_color");
        }

        .sortByDefault {
          padding: 6px 14px;
          box-sizing: border-box;
          border: 1px solid transparent;
          user-select: none;

          &.selected {
            @include theme {
              border: 1px solid getVar("primary_color");
              border-radius: 16px;
            }
          }
        }

        .sortMethod {
          position: relative;
          padding: 8px 16px;
          border-radius: 16px;
          box-sizing: border-box;
          border: 1px solid transparent;
          user-select: none;

          &::before {
            content: '';
            width: 0;
            height: 0;
            border: 6px solid #D8D8D8;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid transparent;
            position: absolute;
            right: 0;
            bottom: 4px;
          }

          &::after {
            content: '';
            width: 0;
            height: 0;
            border: 6px solid #D8D8D8;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid transparent;
            position: absolute;
            right: 0;
            top: 4px;
          }



          &.selectedDown {
            @include theme {
              border-radius: 16px;
              border: 1px solid getVar("primary_color");
            }

            &::after {
              border: 6px solid getVar("primary_color");
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-top: 6px solid transparent;
              position: absolute;
              right: 0;
              top: 4px;
            }
          }

          &.selectedUp {

            @include theme {
              border-radius: 16px;
              border: 1px solid getVar("primary_color");
            }

            &::before {
              border: 6px solid getVar("primary_color");
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-bottom: 6px solid transparent;
              position: absolute;
              right: 0;
              bottom: 4px;
            }

          }
        }
      }
    }

  }

  .content {
    flex-grow: 1;
    width: 100%;
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, $extension-card-width);
    gap: 16px;
    justify-content: space-between;
    align-content: flex-start;
    padding: 0 13px;
    box-sizing: border-box;
    margin-top: 5px;
  }

}

.searchDrawer {
  background-color: #f9f9f9;
  overflow: hidden;
  padding-bottom: 25px;

  .filterItem {
    box-sizing: border-box;
    display: flex;

    .filterCaption {
      flex-basis: 124px;
      flex-shrink: 0;
      font-size: 16px;
      color: #616161;
      text-align: center;
      height: 100%;
      line-height: 42px;
    }

    .filterContentWrapper {
      flex-grow: 1;
      padding: 8px 0;
      position: relative;
      display: flex;
      align-items: center;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 0px;
        height: 100%;
        border: 0.5px dashed #E1E1E1;
      }

      .filterContent {
        display: flex;
        flex-wrap: wrap;
        gap: 18px;
        margin-left: 20px;
      }

    }
  }
}
