@import "css/colors.scss";
@import "css/units.scss";
@import "css/theme.scss";

.modalContent {
    width: 360px;
    color:#575e75
}

.body {
    background: $ui-white;
    padding: 1.5rem 2.25rem;
}

.body input[type="checkbox"],
.body input[type="radio"] {
    margin: 3px;
}

.label {
    font-weight: 500;
    margin: 0 0 0.75rem;
}

.disabledLabel {
    opacity: 0.5;
}

.variableNameTextInput {
    margin-bottom: 1.5rem;
    width: 100%;
    border: 1px solid $ui-black-transparent;
    border-radius: 5px;
    padding: 0 1rem;
    height: 3rem;
    color: $text-primary-transparent;
    font-size: .875rem;
    @include theme{
      outline-color: getVar(primary_color);
    }
}

.infoMessage {
    font-weight: normal;
    font-size: .875rem;
    margin-bottom: 1.5rem;
    text-align: center;
}

.optionsRow {
    display: flex;
    font-weight: normal;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.buttonRow {
    font-weight: bolder;
    text-align: right;
}

.buttonRow button {
    padding: 0.75rem 1rem;
    border-radius: 0.25rem;
    background: white;
    border: 1px solid $ui-black-transparent;
    font-weight: 600;
    font-size: 0.85rem;
    color: #575e75;
}

.buttonRow button.okButton {
    background: $motion-primary;
    border: $motion-primary;
    color: white;
}

[dir="ltr"] .buttonRow button + button {
    margin-left: 0.5rem;
}

[dir="rtl"] .buttonRow button + button {
    margin-right: 0.5rem;
}

.cloudOption {
    display:flex;
    border-top: 1px dashed hsla(0, 0%, 0%, .25);
    overflow: visible;
    padding: 1rem 0;
    text-align: center;
    width: 100%;
    margin: 0 auto;
}

.cloudOptionText {
    opacity: .5;
}
