.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 20px;
  height: 100%;
  text-decoration: none;
  user-select: none;
  opacity: 1;
  pointer-events: auto;

  input::-webkit-input-placeholder {
    color: #fff;
    font-size: 11px;
    font-weight: lighter;
    font-family: "Lucida Grande", Geneva, Verdana, Arial, sans-serif;
  }

  .titleField {
    appearance: none;
    touch-action: manipulation;
    color: hsla(0, 100%, 100%, 1);
    font-weight: bold;
    font-size: 12px;
    // border: 1px dashed hsla(0, 0%, 0%, 0.15);
    border: none;
    background-color: hsla(0, 100%, 100%, 0.25);
    border-radius: 4px;
    background-clip: padding-box;
    height: 30px;
    box-sizing: border-box;
    padding: 5px 5px 5px 10px;
    width: 162px;
    outline: none;

    &:hover {
      background-color: hsla(0, 100%, 100%, 0.5);
    }

    &:focus-within {
      border: 1px solid hsla(0, 100%, 100%, 0);
      box-shadow: 0 0 0 4px hsla(0, 100%, 100%, 0.25);
      background-color: hsla(0, 100%, 100%, 1);
      color: hsla(225, 15%, 40%, 1);
    }
  }
}
