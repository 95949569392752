@import "css/theme.scss";

.container {
  width: 100%;
  text-align: center;

  @include theme {
    color: getVar("invert_text_color")
  }

  .loaderContainer {
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .loaderBar {
    width: 10px;
    height: 5px;
    margin: 0 5px;
    border-radius: 5px;
    animation: loading-wave-animation 1s ease-in-out infinite;

    @include theme{
      background-color: getVar("primary_color");
    }
  }


}


@keyframes loading-wave-animation {
  0% {
    height: 5px;
  }

  50% {
    height: 20px;
  }

  100% {
    height: 5px;
  }
}
