@import "css/theme.scss";

.modal {
  position: fixed;
  inset: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .modalWrapper {
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 9px 28px 8px rgba(0, 0, 0, 0.05);
    margin: 0 auto;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    max-width: calc(100vw - 32px);
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 150px);

    .header {
      height: 44px;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px 0 27px;
      border-radius: 10px 10px 0px 0px;
    
      @include theme {
        background-color: getVar("primary_color");
      }
    
      .left {
        display: flex;
        gap: 16px;
        display: flex;
        align-items: center;

        .icon {
          font-size: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      
        .title {
          font-size: 18px;
          font-weight: 700;
      
          @include theme {
            color: getVar("invert_text_color")
          }
        }
      }

      .closeButton {
        cursor: pointer;
        font-size: 20px;
        height: 20px;
        width: 20px;
        color: rgba(0, 0, 0, 0.45);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s;
        &:hover {
          scale: 1.2;
        }
      }
    }

    .content {
      position: relative;
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      margin:  0 0 8px 0;
      flex-grow: 1;
    }
  }
}
