@import "css/theme.scss";

.tag {
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: #f9f9f9;
  cursor: pointer;
  height: 30px;
  box-sizing: border-box;
  border: 1px solid #E1E1E1;
  user-select: none;

  &:hover {
    @include theme {
      border-color: getVar("primary_color");
    }

    .label {
      @include theme {
        // color: getVar("primary_color");
      }
    }
  }

  &.primary {
    &.selected {
      @include theme {
        background-color: getVar("primary_color");
      }

      .label {
        @include theme {
          color: getVar("invert_text_color");
        }
      }

      &:hover {
        @include theme {
          background-color: getVar("primary_background_hover_color");
          border-color: getVar("primary_border_hover_color");
        }

        .label {
          @include theme {
            color: getVar("invert_text_color");
          }
        }
      }
    }

  }

  &.selected {
    @include theme {
      border: 1px solid getVar("primary_color");
    }

    .label {
      @include theme {
        // color: getVar("primary_color");
      }
    }
  }

  &.square {
    border-radius: 6px;
  }

  .label {
    font-size: 14px;
    font-weight: 300;
    color: #3D3D3D;

    &.closeable {
      margin-right: 4px;
    }
  }

  .closeIcon {
    cursor: pointer;
    margin-left: 4px;
    font-weight: bold;
    vertical-align: middle;
    align-self: center;
    color: #aaa
  }
}
