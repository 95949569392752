@import "css/theme.scss";
@import "css/colors.scss";

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #cecdce;
  background-clip: padding-box;
  min-height: 28px;
  border-radius: 3px;

  @include theme {
    background-color: getVar("scrollbar_color");
  }
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
  border-radius: 3px;
}

::selection {
  color: #fff;
  background-color: #1890ff;

  @include theme {
    background-color: getVar("primary_color");
  }
}


input[type="password"]::-ms-reveal {
  display: none;
}



:global(.blocklyScrollbarHandle) {
  @include theme {
    fill: getVar("scrollbar_color") !important;
  }
}
