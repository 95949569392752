@import "css/theme.scss";



.content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 48px;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;

  .propmt {
    position: relative;
    // width: 100%;
    display: flex;
    color: #AAAAAA;
    gap: 16px;
    align-items: center;
    margin-left: 8px;
    box-sizing: border-box;

    .helpIcon {
      height: 20px;
      width: 20px;
      cursor: pointer;
    }

    .propmtText {
      flex-grow: 1;
      font-size: 12px;
      word-wrap: normal;
    }
  }

  .steps {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 24px;

    .step {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .stepText {
        font-size: 14px;
        color: #A26A00;
      }

      .download {
        display: flex;
        gap: 10px;

        .downloadItem {
          margin-left: 40px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .icon {
            margin: 4px;
            width: 28px;
            height: 28px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .platform {
            font-size: 14px;
            font-weight: 400;
            color: #3D3D3D;
            // margin: 0 0 10px 0;
          }

          .downloadButton {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 6rem;
            border-radius: 1rem;
            background-color: #FFDC99;
            color: #A26A00;
            font-size: 12px;
            font-weight: 500;
            height: 1.5rem;
            cursor: pointer;
            // border-radius: 2px;
            margin: 10px 0 10px 0;

            .img {
              position: absolute;
              height: 18px;
              width: 18px;
              ;
              right: -0.5rem;
              bottom: -0.5rem;
            }
          }

          .disabled {
            background-color: #b1b1b1;
            cursor: default;
            color: white;
          }
        }
      }
    }
  }

}
