@import "css/theme.scss";

.container {

  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;

  .tabs {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 162px;
    align-items: center;
    border-right: 1px solid #ccc;
    overflow: hidden;


    >div {
      text-align: center;
      box-sizing: border-box;
      cursor: pointer;
      user-select: none;

      height: 46px;
      line-height: 46px;
      font-size: 20px;
      width: 182px;
      word-break: break-all;
      overflow: hidden;

      @include theme {
        color: getVar("text_tertiary_color")
      }

      &:first-child {
        margin-top: 44px;
      }
    }

    .active {
      @include theme {
        background: #eee;
        color: getVar("text_tertiary_color");
      }
    }
  }

  .content {
    flex-grow: 1;
    flex-shrink: 0;
    box-sizing: border-box;
    padding: 24px;

    &.show {
      display: block;
    }

    &.hide {
      display: none;
    }
  }
}
