@import "css/theme.scss";
@import "css/colors.scss";


.terminalWrapper {
    position: relative;
    border: 1px solid hsla(0, 0%, 0%, 0.15);
    // border-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: white;
    box-sizing: border-box;

    .header {
        position: relative;
        height: 2rem;
        // background-color: yellowgreen;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);

        .tabs {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            font-size: 0.85rem;

            .tab {
                width: 4rem;
                // border-right: 1px solid black;
                padding: 0 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $text-primary-transparent;
                cursor: pointer;
            }

            .selected {
                color: $text-primary;
                @include theme {
                    box-shadow: inset 0px -2px 0px 0px getVar('primary_color');
                }
            }
        }
        .settings {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            gap: 0.5rem;
            padding: 0 4px;

            .setting {
                padding: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                transition: background-color 0.2s;

                .image {
                    height: 1rem;
                    width: 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
            }

            .selected {
                background-color: $hover-background-color;
            }
        }
    }

    .content {
        position: relative;
        flex-grow: 1;
        flex-shrink: 1;
        background-color: white;
        overflow: hidden;
        .terminal {
            position: relative;
            height: 100%;
        }
    }

    .menuBar {
        height: 2.5rem;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 0 0 10px;

        .image {
            height: 1.5rem;
            width: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .disabled {
            filter: saturate(0);
            cursor: default;
        }
        
        .openButton {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            label {
                padding-inline: 0px;
                width: 2rem;
                height: 2rem;
            }

            .radioContent {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

            }
        }
        .selectBaud {
            cursor: pointer;
        }

        .inputWrapper {
            display: flex;
            flex-direction: row;
            gap: 8px;
            width: 250px;
        }
        .more {
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            color: white;
            width: 2rem;
            cursor: pointer;
            @include theme {
                background-color: getVar('primary_color');
            }

            .moreContent {
                max-height: 10rem;
                overflow-y: auto;
                border-radius: 0.5rem 0.5rem 0 0;
                border: 1px solid rgba(0, 0, 0, 0.15);
                // width: 8rem;
                background-color: white;
                position: absolute;
                bottom: 100%;
                right: 0;
                display: flex;
                flex-direction: column;
                gap: 6px;
                padding: 10px;
                
                @include theme {
                    color: getVar('text_secondary_color');
                }
                .moreItem {
                    position: relative;
                    height: 2rem;
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .moreItemDropdown {
                        min-width: 7rem;
                        border-radius: 0.5rem;
                        padding: 0.25rem 0.5rem;
                        box-sizing: border-box;
                        border: 1px solid rgba(0, 0, 0, 0.15);
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                    
                }
            }
        }
    }
}
