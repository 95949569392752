@import "css/units.scss";
@import "css/colors.scss";
@import "css/z-index.scss";
@import "css/theme.scss";

.container {
  position: fixed;
  pointer-events: none;
  z-index: $z-index-drag-layer;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  direction: ltr;

  .dragging {
    position: absolute;


    img {
      max-width: 80px;
      max-height: 80px;
      min-width: 50px;
      min-height: 50px;

      /* Center the dragging image on the given position */
      margin-left: -50%;
      margin-top: -50%;

      padding: 0.25rem;
      background: $ui-white;
      border-radius: 0.5rem;
      /* Use the same drop shadow as stage dragging */
      box-shadow: 5px 5px 5px $ui-black-transparent;

      @include theme {
        border: 2px solid getVar("primary_color");
      }
    }
  }

}


