.rootContainer {
  position: fixed;
  inset: 0;
  z-index: 1000;
  /* 修改为 auto */
  pointer-events: auto;
  .mask {
    position: absolute;
    inset: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    /* 保持为 auto */
    pointer-events: auto;
  }

  .drawer {
    position: absolute;
    z-index: 1000;
    transition: all .3s;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
    background: #F9F9F9;
    pointer-events: auto;
    /* 添加此行，以确保 drawer 中的内容可以接收事件 */

    &.top {
      top: 0;
      right: 0;
      left: 0;
      box-shadow: 0 6px 16px -8px #00000014, 0 9px 28px #0000000d, 0 12px 48px 16px #00000008;
    }

    &.bottom {
      right: 0;
      bottom: 0;
      left: 0;
      box-shadow: 0 -6px 16px -8px #00000014, 0 -9px 28px #0000000d, 0 -12px 48px 16px #00000008;
    }

    &.left {
      top: 0;
      bottom: 0;
      left: 0;
      box-shadow: 6px 0 16px -8px #00000014, 9px 0 28px #0000000d, 12px 0 48px 16px #00000008;
    }

    &.right {
      top: 0;
      right: 0;
      bottom: 0;
      box-shadow: -6px 0 16px -8px #00000014, -9px 0 28px #0000000d, -12px 0 48px 16px #00000008;
    }
  }
}
