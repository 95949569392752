/* Dropdown.module.scss */
@import "css/units.scss";
@import "css/theme.scss";
@import "css/colors.scss";

.dropdownWrapper {
  position: relative;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.items {
  font-size: .85rem;
  font-weight: bold;
  overflow: visible;
  position: absolute;
  top: 100%;
  box-sizing: border-box;
  list-style-type: none;
  background-clip: padding-box;
  outline: none;
  white-space: nowrap;
  border-radius: 0 0 8px 8px;

  padding: 0;
  margin: 0;
  min-width: 186px;
  max-width: 300px;
  color: hsla(0, 100%, 100%, 1);
  box-shadow: 0 8px 8px 0 hsla(0, 0%, 0%, 0.15);

  @include theme {
    background-color: getVar('primary_color');
    border: 1px solid getVar("primary_border_color");
  }
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.item {
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
  text-indent: 6px;
  line-height: 34px;
  padding: 0 50px 0 5px;
  transition: all 0.3s ease-in-out;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.hasChildren {
    &::before {
      content: '>';
      position: absolute;
      right: 5px;
    }
  }

  &:hover {
    @include theme {
      background-color: getVar("primary_hover_color");
    }
  }

  .subMenu {
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 101;
  }
}

.arrow {
  margin-left: 3px;
}
