.dropdownContainer {
  position: relative;
}

.container {
  position: absolute; // 改为绝对定位
  top: 0;
  left: 0;
  width: 100%;
  min-width: 90px;
  z-index: 100000;

  .wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #000000d9;
    font-size: 14px;
    line-height: 1.5715;
    list-style: none;
    position: relative; // 改为相对定位
    display: block;
    min-width: 60px;

    &.hidden {
      display: none;
    }

    &.show {
      display: block;
    }
  }

  .itemList {
    list-style: none;
    margin: 0;
    padding: 4px 0;
    text-align: left;
    background-color: #fff;
    border-radius: 2px;
    outline: none;
    box-sizing: border-box;
    border: 1px solid #E1E1E1;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .item {
      text-align: center;
      margin: 0;
      font-weight: 400;
      line-height: 22px;
      cursor: pointer;
      transition: all 0.3s;
      font-size: 14px;
      color: #616161;
      padding: 4px 12px;

      &.disabled {
        cursor: not-allowed;
        color: #00000040;

        &:hover {
          background-color: #fff;
        }
      }

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}
