@import "css/units.scss";
@import "css/colors.scss";
@import "css/z-index.scss";
@import "css/theme.scss";

.container {
  height: 44px;
  display: flex;
  flex-shrink: 0;
  align-items: flex-end;
  // width: 200px;
}

.tab {
  cursor: pointer;
  width: 132px;
  height: 80%;
  margin-bottom: 0;
  box-sizing: border-box;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  white-space: nowrap;

  margin-bottom: 0;
  border-radius: 6px 6px 0 0;
  padding: 0.125rem 1.25rem 0;

  @include theme {
    color: getVar("text_quaternary_color");
    background-color: getVar("primary_background_color");
    border: 1px solid getVar("primary_border_color");
  }

  &:hover {
    @include theme {
      border-color: getVar("primary_border_hover_color");
      background-color: getVar("container_bg_hover_color");
    }
  }

  &.isSelected {
    box-shadow: none;
    position: relative;
    height: 90%;
    background-color: hsla(0, 100%, 100%, 1) !important;
    transform: translateY(1px);
    z-index: 4 !important;

    @include theme {
      color: getVar("primary_color") !important;
      border-color: getVar("primary_border_hover_color");
    }

    .icon {
      filter: none;
    }

    &+div {
      border-left: none;
    }

    /* Tab style overrides from react-tabs */
    &::after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 9;
      background: transparent;
    }

    &:focus {
      outline: none;
      box-shadow: none;
      border-color: $ui-black-transparent;

      &::after {
        display: none;
      }
    }

  }
}

[dir="ltr"] .tab {
  margin-left: -6px;
}

[dir="rtl"] .tab {
  margin-right: -6px;
}

[dir="ltr"] .tab:nth-of-type(1) {
  margin-left: 0;
}

[dir="rtl"] .tab:nth-of-type(1) {
  margin-right: 0;
}

.tab:nth-of-type(1) {
  z-index: 3;
}

.tab:nth-of-type(2) {
  z-index: 2;
}

.tab:nth-of-type(3) {
  z-index: 1;
}



.tab .icon {
  width: 1.375rem;
  filter: grayscale(100%);
}

[dir="ltr"] .tab .icon {
  margin-right: 12.5px;
}

[dir="rtl"] .tab .icon {
  margin-left: 0.125rem;
}

/* mirror blocks and sound tab icons */
[dir="rtl"] .tab:nth-of-type(1) .icon {
  transform: scaleX(-1);
}

[dir="rtl"] .tab:nth-of-type(3) .icon {
  transform: scaleX(-1);
}
