@import "css/colors.scss";
@import "css/units.scss";
@import "css/z-index.scss";
@import "css/theme.scss";

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;

  @include theme {
    background-color: getVar("mask_background_color");
  }
}

.modalContent * {
  box-sizing: border-box;
}

.modalContent {
  margin: 100px auto;
  outline: none;
  border: 4px solid $ui-white-transparent;
  padding: 0;
  border-radius: $space;
  user-select: none;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow: hidden;
}

.modalContent.fullScreen {
  position: absolute;

  display: flex;
  height: 100%;
  width: 100%;

  overflow-y: auto;
  -webkit-overflow-scrolling: 'touch';
  user-select: none;

  background-color: $ui-secondary;

  /* Default modal resets */
  margin: 0;
  border: none;
  border-radius: 0;
}

$sides: 20rem;

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  height: $library-header-height;

  box-sizing: border-box;
  width: 100%;

  font-family: "Helvetica Neue",
  Helvetica,
  Arial,
  sans-serif;
  font-size: 1rem;
  font-weight: normal;

  @include theme {
    background-color: getVar("primary_color");
  }
}

.headerItem {
  display: flex;
  align-items: center;
  padding: 1rem;
  text-decoration: none;
  user-select: none;

  @include theme {
    color: getVar("invert_text_color")
  }
}

[dir="ltr"] .headerImage {
  margin-right: 0.5rem;
}

[dir="rtl"] .headerImage {
  margin-left: 0.5rem;
}

.headerItemFilter {
  display: flex;
  flex-basis: $sides;
  justify-content: flex-start;
}

.headerItemTitle {
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
  user-select: none;
  letter-spacing: 0.4px;
  cursor: default;
}

[dir="ltr"] .headerItemTitle {
  margin-right: -100px;
}

[dir="rtl"] .headerItemTitle {
  margin-left: -100px;
}

.fullScreen [dir="ltr"] .headerItemTitle {
  margin-left: -$sides;
}

.fullScreen [dir="rtl"] .headerItemTitle {
  margin: 0 (-$sides) 0 0;
}

.headerItemClose {
  flex-basis: 100px;
  justify-content: flex-end;
  z-index: 1;
}

.fullScreen .headerItemClose {
  order: -1;
  justify-content: flex-start;
}

.backButton {
  font-weight: normal;
  padding-right: 0;
  padding-left: 0;
}

[dir="rtl"] .backButton img {
  transform: scaleX(-1);
}

.headerItemHelp {
  padding: 0;
  z-index: 1;
}

[dir="ltr"] .headerItemHelp {
  margin-right: -4.75rem;
}

[dir="rtl"] .headerItemHelp {
  margin-left: -4.75rem;
}

.helpButton {
  font-weight: normal;
  font-size: 0.75rem;
}

[dir="ltr"] .helpButton {
  padding-right: 0;
}

[dir="rtl"] .helpButton {
  padding-left: 0;
}
