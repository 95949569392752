@import "css/theme.scss";
@import "css/z-index.scss";
@import "css/colors.scss";
@import "css/units.scss";

.container {
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;

  .blockContainer {
    flex-grow: 1;
    width: 100%;
    height: 100%;
  }

  .codeContainer {
    height: 100%;
    box-sizing: border-box;

    .innerBox {
      width: 100%;
      max-width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;

      .codeEditorContainer {
        flex-grow: 1;
      }

      .terminalContainer {
        height: 400px;
        box-sizing: border-box;
      }
    }
  }
}

.serialContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .clear {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 36px;
    cursor: pointer;
    color: #fff !important;
    z-index: 99;
    border: 1px solid;
  }

  .bottom {
    flex-grow: 1;
    height: 30px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    gap: 4px;

    .inputHolder {
      display: flex;
      flex-grow: 1;

      .input {
        flex-grow: 1;
      }
    }

  }
}

.workspaceControlWrapper {
  position: absolute;
  right: 23px;
  bottom: 148px;
}

//Fix an issue where the drag surface was preventing hover events for sharing blocks.
//This does not prevent user interaction on the blocks themselves.
.container :global(.blocklyBlockDragSurface) {
  pointer-events: none;
  z-index: $z-index-drag-layer;
  /* make blocks match gui drag layer */
}

.container :global(.injectionDiv) {
  position: absolute;
  inset: 0;
  border: 1px solid $ui-black-transparent;
  border-top-right-radius: $space;
}
