.WsConnectButton {

  margin: 0 .5rem;
  display: flex;
  padding: 0 0.25rem;
  user-select: none;
  text-decoration: none;
  color: hsla(0, 100%, 100%, 1);
  align-self: center;
  position: relative;
  align-items: center;
  white-space: nowrap;
  height: 2rem;
  background: hsla(30, 100%, 55%, 1);
  cursor: pointer;
  border-radius: calc(0.5rem / 2);
  font-weight: bold;
  padding-left: .75rem;
  padding-right: .75rem;
  box-shadow: 0 0 2px 1px rgba(153, 153, 153, 0.3);
  font-size: 0.85rem;
}

.linking {
  position: relative;
  display: flex;
  height: 100%;
  margin: 0 .25rem;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  
}

.linkingDot {
  text-indent: 2px;
  color: #fff;
  animation: identifierLoading 1s infinite;
  animation-fill-mode: reverse;
  &:nth-child(1){
    animation-delay: 0.1s;
  }
  &:nth-child(2){
    animation-delay: 0.3;
  }

  &:nth-child(3){
    animation-delay: 0.5s;
  }
}


@keyframes identifierLoading {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1.2;
  }
}
