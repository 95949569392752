@import "css/units.scss";
@import "css/colors.scss";
@import "css/z-index.scss";
@import "css/theme.scss";

.controlBar {
    height: 44px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.control {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.85rem;
    font-weight: bold;
}

.controlBarIItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px;
    box-sizing: border-box;
    user-select: none;
}

.controlBarIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    padding: 4px;
    box-sizing: border-box;
    border-radius: .25rem;
    user-select: none;
    cursor: pointer;
}

.codeSwitchWrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 36px;
    // margin: 4px 0;
    padding: 3px;
    background-color: rgb(237,237,237);
    border-radius: 6px;
    box-sizing: border-box;
    overflow: hidden;

    .codeSwitch {
        // background-color: white;
        // background-color: rgb(237,237,237);
        font-size: 0.85rem;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        position: relative;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        justify-content: center;
        align-items: center;

        @include theme {
            color: getVar('text_quaternary_color');
        }

        .switchItem {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            // transform: skew(20deg);
            padding: 0 8px;
            
            border-radius: 4px;
            cursor: pointer;
        }

        .selected {
            background-color: white;
            // box-shadow: 0 0 5px 0px rgba(153, 153, 153, 40%);
            @include theme {
                // border: 1px solid getVar('primary_border_color');
            }
        }
        
    }
    
}
