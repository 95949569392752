@import "css/theme.scss";

.steps {
  font-family: "Roboto";
  position: relative;
  display: flex;
  flex-direction: column;

  .step {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    // align-items: center;
    .numberWrapper {
      position: relative;
      // align-self: flex-start;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 10px 8px 10px 8px;
      background-color: rgba(149, 202, 240, 0.2);
      margin: 0 2rem 0 0;
      // padding: 0 2rem 0 0;

      .number {
        // align-self: flex-start;
        height: 1.2rem;
        width: 1.2rem;
        border-radius: 1rem;
        background-color: #628DFF;
        font-size: 0.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
      }
    }
    
    &::after {
      content: '';
      position: absolute;
      left: calc(7px + 0.6rem);
      top: 32px;
      width: 1px;
      border-left: 2px dotted #95CAF0;
      height: calc(100% - 32px + 10px - 3px);
      z-index: 0;
    }
    /* 最后一步没有连接线 */
    &:last-child::after {
      content: none;
    }

    &:first-child {
      .numberWrapper {
        border-radius: 6px 6px 0 0;
      }
    }

    &:last-child {
      .numberWrapper {
        border-radius: 0 0 6px 6px;
        height: 100%; // 高度坍塌
      }
    }

    

    

    .stepContent {
      margin: 0.5rem 0 1rem 0;

    }

  }
}
