@import "css/theme.scss";

.container {
  position: relative;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.flexWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  align-items: start;
}
.tabsWrapper {
  width: 100%;
  z-index: 30;

  @include theme{
    background-color: getVar("body_bg_color");
  }
}

.blockWrapper {
  position: relative;
  height: 100%;
  width: 100%;
}


.watermark {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  pointer-events: none;
}
