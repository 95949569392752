@import "css/theme.scss";
@import "css/units.scss";

$extension-card-width: 218px; // 212px
$extension-card-height: 247px; //207
$extension-card-img-height: 136px;
$extension-footer-height: 28px;
$default-card-border-color: rgba(0, 0, 0, 0.05);

.spriteCard {
  width: $extension-card-width;
  height: $extension-card-height;
  position: relative;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  border: 2px solid $default-card-border-color;
  color: #575e75;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    @include theme {
      border-color: getVar("primary_color");
    }
  }

  &:has(.disabled),
  &:has(.download),
  &:has(.needLink) {
    &:hover {
      border-color: $default-card-border-color;
    }
  }

  .favorite {
    position: absolute;
    top: 6px;
    left: 7px;
    z-index: 2;

    path {
      @include theme {
        fill: getVar("primary_color");
      }
    }
  }

  .downloading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    border-radius: 6px;
  }

  .needLink {
    position: absolute;
    inset: 0;
    background: rgba(128, 128, 128, 0.4);
    z-index: 999;
    cursor: default;
    border-radius: 6px;

    .needLinkTag {
      border-radius: 0px 6px 0px 6px;
      position: absolute;
      right: 0;
      top: 0;
      font-family: Source Han Sans;
      color: #fff;
      background-color: #7A7A7A;
      font-weight: 500;
      font-size: 12px;
      padding: 5px 7px;
    }
  }

  .disabled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - #{$extension-footer-height});
    background-color: rgba(249, 249, 249, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    cursor: default;
    border-radius: 6px 6px 0 0;

    .disabledTag {
      font-family: Source Han Sans;
      box-sizing: border-box;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 0px 6px 0px 6px;
      opacity: 1;
      background: #E7E7E7;
      border: 1px solid #7A7A7A;
      color: #7A7A7A;
      font-weight: 500;
      font-size: 12px;
      padding: 5px 7px;
    }
  }

  &.selected {
    @include theme {
      border-color: getVar("primary_color");
      box-shadow: 0px 0px 10px 0px getVar("primary_box_shadow_color");
    }
  }

  .download {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - #{$extension-footer-height});
    background: rgba(216, 216, 216, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    cursor: default;
    border-radius: 6px 6px 0 0;

    .downloadIcon {
      position: absolute;
      top: 6px;
      right: 7px;
      cursor: pointer;
      line-height: 30px;
      z-index: 2;

      path {
        @include theme {
          fill: getVar("primary_color");
        }
      }

      .border {
        @include theme {
          stroke: getVar("primary_color");
        }
      }
    }
  }

  .remove {
    position: absolute;
    top: 6px;
    right: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 3px;
    z-index: 2;

    @include theme {
      color: getVar("primary_color");
      font-size: 12px;
      font-weight: 500;
    }

    .removeIcon {
      path {
        @include theme {
          fill: getVar("primary_color");
        }
      }
    }
  }

  .image {
    position: relative;
    width: 100%;
    height: $extension-card-img-height;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    border-bottom: 1px solid #E9EEF2;
    flex-shrink: 0;
    border-radius: 6px 6px 0 0;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .update {
      position: absolute;
      top: 0;
      right: 0;
      width: 60px;
      height: 30px;
      border-bottom-left-radius: 10px;
      color: white;
      background-color: #8bc34a;
      cursor: pointer;
      line-height: 30px;
      text-align: center;
      border-bottom-left-radius: 10px;
    }

    .versions {
      position: absolute;
      right: 0;
      bottom: 0px;
      // height: 16px;
    }
  }

  .featuredText {
    flex-grow: 1;
    width: 100%;
    box-sizing: border-box;

    .name {
      text-align: center;
      box-sizing: border-box;
      color: #6d6d6d;
      font-size: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 700;
      padding-top: 7px;
      overflow: hidden;
    }

    .description {
      text-align: center;
      padding: 5px;
      font-weight: 400;
      color: #a5a5a5;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      line-height: 1.5em;
      max-height: 3em;
    }
  }

  .operation {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .bottom {
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
  }

  .sku {
    position: absolute;
    right: 3px;
    top: calc(#{$extension-card-img-height} - 23px);
    font-weight: 500;
    font-size: 12px;
    display: flex;
    gap: 3px;

    .skuItem {
      @include theme {
        color: getVar("primary_text_color");
        background-color: getVar("invert_text_color");
        border: 1px solid getVar("primary_border_color");
        border-radius: 6px;
        height: 17px;
        padding: 2px 4px;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $extension-footer-height;
    font-size: 10px;
    padding: 0 8px;
    color: #AAAAAA;
    flex-shrink: 0;

    .author {
      display: flex;
      gap: 5.2px;
      align-items: center;

      img {
        max-width: 10px;
        max-height: 10px;
      }
    }

    .versions {}

    .more {
      cursor: pointer;

      .moreIcon path {
        @include theme {
          fill: getVar("primary_color")
        }
      }
    }
  }
}
