@import "css/units.scss";
@import "css/colors.scss";
@import "css/theme.scss";

.libraryScrollGrid {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    background: $ui-secondary;
    flex-grow: 1;
    flex-wrap: wrap;
    overflow-y: auto;
    height: auto;
    padding: 0.5rem;
    height: calc(100% - #{$library-header-height});
}

.libraryScrollGrid.withFilterBar {
    height: calc(100% - #{$library-header-height} - #{$library-filter-bar-height} - 2rem);
}

.filterBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: calc(#{$library-filter-bar-height} + 2rem); /* padding */
    padding: 0 1rem;
    font-size: .875rem;
    @include theme{
      background-color: getVar("primary_background_color");
    }
}

.filterBarItem {
    margin-right: .75rem;
}

.filter {
    flex-grow: 0;
}

.filterInput {
    width: 11.5rem;
    transition: .2s;
}

.filterInput:focus,
.filterInputt:not([value=""]) {
    width: 18.75rem;
}

.divider {
    transform: scaleY(1.39);
    height: $library-filter-bar-height;
}

.tagWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: $library-filter-bar-height;
    overflow: hidden;
}

.spinnerWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
